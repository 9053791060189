import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Title,
  Wrapper,
  Content,
  Box,
  Header,
  Description,
} from './styles';
import { TabPanel } from '../../../../components/TabPanel';
import Category from '../../Body/Category';
import { itemSelected } from '../../../../store/modules/item/actions';
import { PageHeader } from '../../../../components/PageHeader';

function Main() {
  const dispatch = useDispatch();
  const [tab, setTab] = useState('groups');
  const [category, setCategory] = useState({ subcategories: [] });
  const [subcategory, setSubcategory] = useState({});

  const { groups, categories } = useSelector(state => state.data);

  const handleOnGroupClick = group => {
    const data = categories
      .filter(item => item.group_id === group.group_id)
      .map(item => {
        const { category_id, name, description, order, url } = item;
        return {
          category_id,
          name,
          description,
          order,
          url,
        };
      });

    const cat = {
      ...group,
      name: group.name.toLowerCase(),
      subcategories: data,
    };

    setCategory(cat);
    setTab('subgroups');
  };

  const handleOnSubgroupClick = subgroup => {
    const data = categories.find(
      item => item.category_id === subgroup.category_id
    );

    setSubcategory(data);
    setTab('products');
  };

  const handleClickItem = product => {
    dispatch(itemSelected(product));
  };

  const handleBack = () => {
    if (tab === 'subgroups') {
      setTab('groups');
    } else {
      setTab('subgroups');
    }
  };

  return (
    <Container>
      {(tab === 'subgroups' || tab === 'products') && (
        <PageHeader handleClose={handleBack} title="voltar" />
      )}
      <TabPanel value={tab} index="groups">
        <Wrapper>
          <Header>
            <Title>Categorias</Title>
          </Header>
          <Content>
            {groups.map(group => (
              <Box
                key={group.group_id}
                onClick={() => handleOnGroupClick(group)}
              >
                <img src={group.url} alt={group.name} />
                <span>{group.name.toLowerCase()}</span>
              </Box>
            ))}
          </Content>
        </Wrapper>
      </TabPanel>
      <TabPanel value={tab} index="subgroups">
        <Wrapper>
          <Header>
            <Title>{category.name}</Title>
            <Description>{category.description}</Description>
          </Header>
          <Content>
            {category.subcategories.map(subgroup => (
              <Box
                key={subgroup.category_id}
                onClick={() => handleOnSubgroupClick(subgroup)}
              >
                <img src={subgroup.url} alt={subgroup.name} />
                <span>{subgroup.name}</span>
              </Box>
            ))}
          </Content>
        </Wrapper>
      </TabPanel>
      <TabPanel value={tab} index="products">
        <Category
          key={subcategory.category_id}
          category={subcategory}
          handleClickItem={handleClickItem}
        />
      </TabPanel>
    </Container>
  );
}

export { Main };
