import styled from 'styled-components';

const width = (window.screen.width - 38) / 2;

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 15px;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  min-height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const Title = styled.span`
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid rgba(149, 149, 149, 0.1);
`;

export const Description = styled.span`
  flex: 1;
  font-size: 0.775rem;
  font-weight: 400;
  color: #959595;
  padding: 1rem 0;
`;

export const Content = styled.div`
  width: 100%;
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${width}px, 1fr));
  grid-gap: 8px;
`;

export const Box = styled.button`
  position: relative;
  width: ${width}px;
  height: ${width}px;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  border-radius: 8px;

  background-color: rgba(0, 0, 0, 0.6);

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;

    opacity: 0.3;
  }

  & span {
    position: absolute;
    bottom: 12px;
    left: 12px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }

  cursor: pointer;

  &:hover {
    & img {
      opacity: 0.6;
    }
  }
`;
