import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CallIcon from '@material-ui/icons/Call';

import MaskService from 'react-masked-text/src/mask-service';
import useStyles from './styles';

export default function Header(props) {
  const classes = useStyles(props);

  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [journey, setJourney] = useState(null);
  const [value, setValue] = useState(null);

  const { company } = useSelector(state => state.data);

  useEffect(() => {
    setName(company.name);
    const cel = MaskService.toMask('cel-phone', `${company.phone}`);
    setPhone(cel);
    setJourney(company.journey);
    setValue(company.minimum_value.toFixed(2));
  }, [company]);

  return (
    <div className={classes.root}>
      {/* <div className={classes.cover} /> */}
      <div className={classes.container}>
        <span className={classes.title}>{name}</span>
        <div className={classes.info}>
          <div className={classes.field}>
            <CallIcon style={{ fontSize: '1.3rem' }} />
            <span className={classes.text}>{phone}</span>
          </div>
          <div className={classes.field}>
            <AccessTimeIcon style={{ fontSize: '1.3rem' }} />
            <span className={classes.text}>{journey}</span>
          </div>
          <div className={classes.field}>
            <AttachMoneyIcon style={{ fontSize: '1.3rem' }} />
            <span>{value}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
