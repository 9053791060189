import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 15px',
    backgroundColor: '#fafafa',
    borderTop: '1px solid rgba(149, 149, 149, 0.1)',
  },
  header: {
    minHeight: '4rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    fontWeight: '500',
    padding: '20px 0px',
  },
  description: {
    flex: 1,
    fontSize: '0.775rem',
    fontWeight: '400',
    color: '#959595',
    padding: '1rem 0',
  },
});

export default useStyles;
